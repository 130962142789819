"use client"

import { AppProgressBar as ProgressBar } from "next-nprogress-bar"
import React from "react"

const ProgressBarProvider = ({ children }) => {
  return (
    <>
      <ProgressBar
        color="#8E00D6"
        options={{ showSpinner: false }}
        shallowRouting={true}
        startPosition={0.3}
      />
      {children}
    </>
  )
}

export default ProgressBarProvider
